import axios from "axios";

export default class UsersService {
  getAll(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "usuarios/all")
  }
  getById(id){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "usuarios/admin/"+id)
  }
  createUserAdmin(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "usuarios/create-admin",data)
  }
  createUserPublic(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "usuarios/create-public",data)
  }
  deleteUser(id){
    const data={
      id
    }
    return axios.delete(process.env.VUE_APP_API_PUBLIC + "usuarios",{data})
  }
  editUserAdmin(data){
    return axios.put(process.env.VUE_APP_API_PUBLIC + "usuarios/update-admin",data)
  }
  editUserPublic(data){
    return axios.put(process.env.VUE_APP_API_PUBLIC + "usuarios/update-public",data)
  }
  changePassword(data){
    return axios.put(process.env.VUE_APP_API_PUBLIC + "usuarios/change-password",data)
  }

  contactanos(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "contactanos",data)
  }

  deletePerfil(){
    return axios.delete(process.env.VUE_APP_API_PUBLIC + "usuarios/perfil")
  }
}
